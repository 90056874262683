/* eslint-disable @nfq/no-magic-numbers */
import {media} from '@nfq/react-grid';
import styled from 'styled-components';

import {getDesktopFontSize, getFontSize} from 'UI/components/layout/ultra/Text/utils';

import type {Colors} from 'types/global';

interface HeadlineProps {
    /**
     * The optional alignment parameter indicating the desired text alignment ('center', 'end', or 'start').
     */
    $align?: 'center' | 'end' | 'start';
    /**
     * The optional transform parameter indicating the desired text transformation ('uppercase').
     */
    $transform?: 'uppercase';
}

const Headline = styled.h1<HeadlineProps>`
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    text-align: ${({$align}) => $align ?? 'inherit' as const};
    text-transform: ${({$transform}) => $transform ?? 'none' as const};
`;

export const H2 = styled(Headline).attrs({as: 'h2'})`
    font-size: 3.2rem;

    ${media('lg')} {
        font-size: 6.4rem;
    }
`;

export const H3 = styled(Headline).attrs({as: 'h3'})`
    font-size: 2.4rem;

    ${media('lg')} {
        font-size: 4.8rem;
    }
`;

export const H4 = styled(Headline).attrs({as: 'h4'})`
    font-size: 2rem;

    ${media('lg')} {
        font-size: 3.2rem;
    }
`;

export const H5 = styled(Headline).attrs({as: 'h5'})`
    font-size: 2rem;
    line-height: 2.6rem;

    ${media('lg')} {
        font-size: 2.4rem;
        line-height: 3.1rem;
    }
`;

interface TextProps {
    $align?: 'center' | 'end' | 'start';
    $size?: 'lg' | 'md' | 'sm' | 'xs';
    $weight?: 'normal' | 'semibold';
}

export const P = styled.p<TextProps>`
    font-family: ${({theme}) => theme.fonts.Dinot};
    font-size: ${getFontSize};
    font-weight: ${({$weight}) => ($weight === 'semibold' ? 500 : 400)};
    line-height: 1.3;
    margin: 0;
    text-align: ${({$align}) => $align ?? 'inherit' as const};

    ${media('lg')} {
        font-size: ${getDesktopFontSize};
        line-height: 1.4;
    }
`;

interface ColorProps {
    /**
     * The color value to be applied to the text content.
     * The possible colors are determined by the pallette defined in the theme.
     */
    $color: Colors;
}

/**
 * The Color component is a styled `<span>` component.
 *
 * @param props        The props of the component.
 * @param props.$color The color value to be applied to the text content.
 * @returns            A React component.
 *
 * @example
 * ```tsx
 * const App = () => {
 *     const colors = useThemeColors();
 *
 *     return <Color $color={colors.primaryFontColor}>Hello, World!</Color>;
 * };
 * ```
 */
export const Color = styled.span<ColorProps>`
    color: ${({$color}) => $color};
    transition: color 0.2s ease-in-out;
`;