import {api} from '@nfq/typed-next-api';

import type {HTTP_METHODS, MutationRepoArgs} from '@nfq/typed-next-api';
import type {loginMerchant, logoutMerchant} from 'ApiRoutes/login';
import type {LoginAdapter, LoginBody} from 'Domain/admin/adapters/LoginAdapter';

/**
 * `LoginService` class implements the `LoginAdapter` interface.
 * This class provides methods to handle user login and logout functionalities.
 * It uses an API service to make the actual requests to the server.
 *
 * @example
 * ```tsx
 * const loginService = new LoginService();
 * ```
 */
class LoginService implements LoginAdapter {
    /**
     * Asynchronously performs the user login operation.
     * This method sends a POST request to the login API with the user's login credentials.
     *
     * @param key             A unique string key to identify the login operation, used for caching or tracking the request.
     * @param args            An object containing the argument for the mutation repository.
     * @param args.arg        An object containing the argument for the mutation repository.
     * @param args.arg.body   The login credentials, including accessToken and email.
     * @param args.arg.method The HTTP method used for the logout request, restricted to `HTTP_METHODS.DELETE` in this context.
     * @returns A Promise that resolves to `true` when the login operation is successful.
     *
     * @example
     * ```tsx
     * await loginService.login('user-login-key', { arg: { body: loginCredentials, method: HTTP_METHODS.POST } });
     * ```
     */
    async login(
        key: string,
        {arg: {body, method}}: MutationRepoArgs<LoginBody, HTTP_METHODS.POST>
    ) {
        await api<typeof loginMerchant>('/api/login', {
            body: {
                accessToken: body.accessToken,
                email: body.email
            },
            method
        });

        return true;
    }

    /**
     * Asynchronously performs the logout operation for a user. This function makes an API call to log the user out of the system.
     * It accepts a key to uniquely identify the logout operation and an object specifying the HTTP method to use, typically a DELETE request.
     *
     * @param key             A unique string key to identify the logout operation. This can be used for caching or tracking the logout request.
     * @param args            An object containing the argument for the mutation repository.
     * @param args.arg        An object containing the argument for the mutation repository.
     * @param args.arg.method The HTTP method used for the logout request, restricted to `HTTP_METHODS.DELETE` in this context.
     * @returns A Promise that resolves to `true` once the logout operation is successfully completed.
     *
     * @example
     * ```tsx
     * await logout('user-logout-key', { arg: { method: HTTP_METHODS.DELETE } });
     * ```
     */
    async logout(
        key: string,
        {arg: {method}}: MutationRepoArgs<never, HTTP_METHODS.DELETE>
    ) {
        await api<typeof logoutMerchant>('/api/login', {method});

        return true;
    }
}

export default new LoginService();