import type {Variants} from 'framer-motion';

export const RareDealsLayoutTransition: Variants = {
    enter: {
        opacity: 1,
        transition: {
            duration: 0.5,
            when: 'beforeChildren'
        }
    },
    exit: {
        opacity: 0,
        transition: {
            duration: 0.5,
            when: 'afterChildren'
        }
    },
    initial: {opacity: 0}
};

export const DropdownTransition: Variants = {
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: -10
    },
    initial: {
        opacity: 0,
        y: -10
    }
};

export const AccordionAnimation: Variants = {
    hidden: {height: 0},
    visible: {
        height: 'auto',
        transition: {
            duration: 0.5,
            ease: 'easeInOut'
        }
    }
};

export const LoginSwitchTransition: Variants = {
    animate: {
        opacity: 1,
        position: 'relative',
        width: '100%',
        y: 0
    },
    exit: {
        opacity: 0,
        position: 'absolute',
        width: '100%',
        y: -10
    },
    initial: {
        opacity: 0,
        y: -10
    }
};

export const BasketDrawerAnimation: Variants = {
    animate: {x: 0},
    exit: {x: '100%'},
    initial: {x: '100%'}
};

export const BackdropAnimation: Variants = {
    animate: {opacity: 1},
    exit: {opacity: 0},
    initial: {opacity: 0}
};

export const BasketElementsAnimation: Variants = {
    animate: {
        opacity: 1,
        y: 0
    },
    exit: {
        opacity: 0,
        y: -20
    },
    initial: {opacity: 0}
};

export const FlyoutAnimation: Variants = {
    /**
     * Represents the enter animation state for the flyout.
     * The enter animation sets the opacity to 1 and the y-axis position to '-100%' or '100%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is entering from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    enter({isOnTop}) {
        return {
            opacity: 1,
            y: isOnTop ? '-100%' : '100%'
        };
    },
    /**
     * Represents the exit animation state for the flyout.
     * The exit animation sets the opacity to 0 and the y-axis position to '-110%' or '110%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is exiting from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    exit({isOnTop}) {
        return {
            opacity: 0,
            y: isOnTop ? '-110%' : '110%'
        };
    },
    /**
     * Represents the initial animation state for the flyout.
     * The initial animation sets the opacity to 0 and the y-axis position to '-110%' or '110%' based on the top parameter.
     *
     * @param params          An object containing the isOnTop and position parameters.
     * @param params.isOnTop  A boolean value indicating whether the animation is starting from the top.
     * @param params.position A string value indicating the position of the flyout.
     * @returns An object containing the animation properties for opacity and y-axis position.
     */
    initial({isOnTop}) {
        return {
            opacity: 0,
            y: isOnTop ? '-110%' : '110%'
        };
    }
};