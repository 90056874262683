import type {MouseEvent} from 'react';
import React from 'react';

import {media, translucify} from '@nfq/react-grid';
import Link from 'next/link';
import styled from 'styled-components';

import type {ButtonSize, ButtonVariant, ButtonWidth} from 'UI/components/action/raredeals/button/utils';
import {getButtonBgColor, getButtonBgHoverColor, getButtonColor} from 'UI/components/action/raredeals/button/utils';

import type {WithChildren} from 'types/global';

type LinkRel = 'nofollow noindex noopener noreferrer' | 'nofollow noindex noopener' | 'nofollow noindex noreferrer' |
'nofollow noindex' | 'nofollow noopener noreferrer' | 'nofollow noopener' | 'nofollow noreferrer' | 'nofollow' |
'noindex noopener noreferrer' | 'noindex noopener' | 'noindex noreferrer' | 'noindex' | 'noopener noreferrer' |
'noopener' | 'noreferrer';

/**
 * Defines the base properties for a button or link component. This interface supports conditional types based on the component type.
 * It provides a flexible way to define properties common to both buttons and links, as well as properties specific to each.
 * This approach simplifies the creation of a component that can render either a button or a link based on the provided props.
 */
interface BaseProps<C extends 'button' | 'link'> {
    /**
     * Specifies the element type to render: 'button' for a `<button>` element or 'link' for an `<Link>` element.
     * - `'button'`: Render a button element.
     * - `'link'`: Render a next link element.
     *
     * @default 'button'
     */
    as: C;
    /**
     * Optional CSS class name to customize the appearance of the button or link.
     */
    className?: string;
    /**
     * If the component is disabled, it will not be clickable and will have a different visual appearance.
     */
    isDisabled?: boolean;
    /**
     * Determines the size of the button or link, affecting its dimensions and spacing.
     * - `'default'`: Render an default button.
     * - `'small'`: Render an small button.
     *
     * @default 'default'
     */
    size?: ButtonSize;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
    /**
     * The visual style of the button or link.
     */
    variant?: ButtonVariant;
    /**
     * Determines the size of the button or link, affecting its dimensions and spacing.
     */
    width?: ButtonWidth;
}

/**
 * Extends `BaseProps` to include additional properties specific to either a button or a link. This type uses conditional types
 * based on the value of `as` to include or exclude properties accordingly, ensuring type safety and relevance of props based on the element type.
 */
type ButtonProps<C extends 'button' | 'link'> = BaseProps<C> & (C extends 'button' ? {
    formId?: string;
    /**
     * Do not use as it is not applicable to buttons.
     */
    href?: never;
    /**
     * Optional click event handler.
     */
    onClick?(e: MouseEvent<HTMLButtonElement>): void;
    /**
     * Do not use as it is not applicable to buttons.
     */
    rel?: never;
    /**
     * Do not use as it is not applicable to buttons.
     */
    target?: never;
    /**
     * Defines the button type.
     * - `'button'`: An normal button.
     * - `'reset'`: An reset button for forms.
     * - `'submit'`: An submit button for forms.
     *
     * @default 'button'
     */
    type?: 'button' | 'reset' | 'submit';
} : C extends 'link' ? {
    formId?: never;
    /**
     * URL for the link element to link to.
     */
    href: string;
    /**
     * Optional click event handler.
     */
    onClick?(e: MouseEvent): void;
    /**
     * Defines the relationship between the current document and the linked URL.
     * - `'nofollow'`: Indicates that the link is not endorsed by the original document's author.
     * - `'noindex'`: Indicates that the linked document should not be indexed by search engines.
     * - `'noopener'`: Ensures that the linked document does not have access to the originating document.
     * - `'noreferrer'`: Ensures that the linked document does not have access to the originating document and does not pass referrer information.
     * Also every combination of these values is possible.
     */
    rel?: LinkRel;
    /**
     * Specifies where to open the linked URL.
     * - `'_blank'`: Opens the linked document in a new window or tab.
     * - `'_parent'`: Opens the linked document in the parent frame.
     * - `'_self'`: Opens the linked document in the same frame as it was clicked.
     * - `'_top'`: Opens the linked document in the full body of the window.
     */
    target?: '_blank' | '_parent' | '_self' | '_top';
    /**
     * Do not use as it is not applicable to links.
     */
    type?: never;
} : never);

/**
 * A versatile button component that supports both `<button>` and `<a>` (link) elements through a single unified interface.
 * It can be used in various parts of an application where user interaction is required. The component is highly customizable
 * with support for different sizes, styles, and variants. It also allows for additional HTML attributes specific to buttons and links.
 *
 * @param props            The component props.
 * @param props.as         Specifies the element type to render: 'button' for a `<button>` element or 'link' for an `<Link>` element.
 * @param props.children   The content to render inside the button or link.
 * @param props.className  Optional CSS class name to customize the appearance of the button or link.
 * @param props.formId     Optional form ID to associate the button with a form.
 * @param props.href       URL for the link element to link to.
 * @param props.isDisabled If the component is disabled, it will not be clickable and will have a different visual appearance.
 * @param props.onClick    Optional click event handler.
 * @param props.rel        Defines the relationship between the current document and the linked URL.
 * @param props.target     Specifies where to open the linked URL.
 * @param props.testId     A unique identifier, usually in the form of a string, assigned to the component for testing purposes.
 * @param props.type       Defines the button type.
 * @param props.variant    The visual style of the button or link.
 * @param props.size       Determines the size of the button or link, affecting its dimensions and spacing.
 * @param props.width      Determines the width of the button or link, affecting its dimensions and spacing.
 * @returns A button or link element styled according to the provided props.
 *
 * @example
 * ```tsx
 * // Using Button as a link
 * <Button
 *   as="link"
 *   href="https://example.com"
 *   size="medium"
 *   variant="contained"
 *   width="wide"
 *   testId="link-button-example"
 * >
 *   Visit Example
 * </Button>
 *
 * // Using Button as a button
 * <Button
 *   as="button"
 *   onClick={() => // Button clicked
 *   size="small"
 *   variant="secondary"
 *   width="auto"
 *   type="submit"
 *   testId="button-example"
 * >
 *   Submit
 * </Button>
 * ```
 */
const Button = <C extends 'button' | 'link'>({
    as,
    children,
    className,
    formId,
    href,
    isDisabled = false,
    onClick,
    rel,
    size = 'default',
    target,
    testId = 'Button',
    type = 'button',
    variant = 'primary',
    width = 'auto'
}: WithChildren<ButtonProps<C>>
) => (
    <Wrapper
        $size={size}
        $variant={variant}
        $width={width}
        as={as === 'link' ? Link : 'button'}
        className={className}
        data-cy={testId}
        disabled={isDisabled}
        form={formId}
        href={href}
        rel={rel}
        target={target}
        type={as === 'button' ? type : undefined}
        onClick={onClick}
    >
        {children}
    </Wrapper>
    );

Button.displayName = 'Button';

export {Button};

interface WrapperProps {
    $size: ButtonSize;
    $variant: ButtonVariant;
    $width: ButtonWidth;
}

const Wrapper = styled.button<WrapperProps>`
    align-items: center;
    background-color: ${({$variant}) => getButtonBgColor($variant)};
    border: ${({$variant}) => ($variant === 'primary' ? '2px' : '1px')} solid transparent;
    border-color: ${({$variant, theme}) => ($variant === 'tertiary' ? translucify(theme.colors.raredealsButtonTertiaryBorderColor, 30) : 'transparent')};
    border-radius: 10rem;
    color: ${({$variant}) => (getButtonColor($variant))};
    cursor: pointer;
    display: flex;
    gap: 0.3rem;
    justify-content: center;
    outline: 0;
    padding: ${({$size}) => ($size === 'small' ? '0.8rem 1.6rem' : '1.6rem 2.4rem')};
    text-align: center;
    transition: background-color 0.25s ease-in-out, border-color 0.25s ease-in-out, opacity 0.25s ease-in-out;
    width: ${({$width}) => ($width === 'full' ? '100%' : 'auto')};

    &:hover {
        background-color: ${({$variant}) => getButtonBgHoverColor($variant)};
        border-color: ${({$variant, theme}) => ($variant === 'tertiary' ? theme.colors.raredealsButtonTertiaryBorderColor : 'transparent')};
        opacity: ${({$variant}) => ($variant === 'ghost' ? '0.7' : '1')};
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    ${media('md')} {
        padding: 1.6rem 2.4rem;
    }

    &:focus-visible {
        border-color: ${({$variant, theme}) => ($variant === 'primary' ? theme.colors.raredealsButtonSecondaryBgColor : theme.colors.raredealsButtonPrimaryBgColor)};

        ${({$variant, theme}) => $variant === 'primary' && `
            box-shadow: ${theme.boxShadows.raredealsButtonPrimaryFocusShadow};
            border-color: ${theme.colors.raredealsButtonPrimaryFocusBorderColoor};
        `}

        ${({$variant, theme}) => $variant === 'secondary' && `
            box-shadow: ${theme.boxShadows.raredealsButtonSecondaryFocusShadow};
            border-color: ${theme.colors.raredealsButtonSecondaryFocusBorderColor};
        `}
    }
`;