import {Col, spacing} from '@nfq/react-grid';
import Link from 'next/link';
import {useTranslation} from 'next-i18next';
import styled from 'styled-components';

import {Color, P} from 'UI/components/layout/raredeals/Text';

import type {Colors} from 'types/global';

/**
 * The `ComponentProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ComponentProps {
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     */
    testId?: string;
    /**
     * The `textColor` property represents the color of the text.
     * It is a required property and must be provided when an object of type `ComponentProps` is expected.
     */
    textColor: Colors;
}

/**
 * The `FooterLinks` component renders a row of navigational links in the footer of a webpage, specifically targeting legal and policy-related pages.
 * It utilizes the `useTranslation` hook to fetch localized text for the links, ensuring that the link labels and URLs are appropriate for the user's locale.
 * Each link is styled and configured to open in a new tab with proper rel attributes to enhance security. This component is part of the website's footer,
 * playing a critical role in providing users with easy access to important informational pages such as terms and conditions, imprint, and privacy policies.
 *
 * @param props           The component props.
 * @param props.testId    A string that provides a unique identifier for the component, aiding in targeted testing and debugging.
 * @param props.textColor A string specifying the CSS color value that will be used to style the text of the links.
 *
 * @returns A React component that displays a series of links in the footer section, facilitating user access to key informational resources.
 *
 * @example
 * ```tsx
 * // Usage example of the FooterLinks component within a footer layout.
 * <FooterLinks testId="mainFooterLinks" textColor="#ffffff" />
 * ```
 */
const FooterLinks = ({testId = 'FooterLinks', textColor}: ComponentProps) => {
    const {t} = useTranslation('raredeals', {keyPrefix: 'footer'});

    return (
        <Links data-cy={testId} direction="row" justify={{lg: 'flex-end', xs: 'center'}}>
            <StyledLink href={t('conditionsUrl')} rel="noopener noreferrer" target="_blank">
                <P $size="xs" $weight="semibold">
                    <Color $color={textColor}>
                        {t('conditions')}
                    </Color>
                </P>
            </StyledLink>
            <StyledLink href={t('imprintUrl')} rel="noopener noreferrer" target="_blank">
                <P $size="xs" $weight="semibold">
                    <Color $color={textColor}>
                        {t('imprint')}
                    </Color>
                </P>
            </StyledLink>
            <StyledLink href={t('privacyUrl')} rel="noopener noreferrer" target="_blank">
                <P $size="xs" $weight="semibold">
                    <Color $color={textColor}>
                        {t('privacy')}
                    </Color>
                </P>
            </StyledLink>
        </Links>
    );
};

FooterLinks.displayName = 'FooterLinks';

export {FooterLinks};

const Links = styled(Col)`
    gap: ${spacing(4)};
    position: relative;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    transition: opacity .25s ease-in-out;

    &:hover {
        opacity: 0.7;
    }
`;