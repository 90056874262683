import {theme} from 'UI/utils/globalStyles';

/**
 * Specifies the visual appearance variant of the button, affecting its stylistic design like borders and shadows.
 */
export type ButtonVariant = 'ghost' | 'primary' | 'secondary' | 'tertiary';

/**
 * Defines the possible sizes for buttons, affecting their height and padding.
 */
export type ButtonSize = 'default' | 'small';

/**
 * Defines the possible sizes for buttons, affecting their height and padding.
 */
export type ButtonWidth = 'auto' | 'full';

/**
 * The `getButtonBgColor` function determines the background color for buttons based on their variant type.
 * This utility function supports several button styles—'ghost', 'primary', 'secondary', and defaults to 'tertiary'.
 * By specifying the variant, it returns the corresponding background color from a centralized theme configuration.
 * This function is crucial for maintaining consistent styling across all buttons within the application, ensuring
 * that each button variant is visually distinct and aligns with the overall design system.
 *
 * @param variant A string indicating the button variant, which directly affects the button's background color.
 * @returns A string representing the CSS color value for the button's background based on the specified variant.
 *
 * @example
 * ```ts
 * const buttonColor = getButtonBgColor('primary');
 * console.log(buttonColor); // Outputs the background color defined for primary buttons in the theme.
 * ```
 */
export const getButtonBgColor = (variant: ButtonVariant) => {
    if (variant === 'ghost') {
        return 'transparent';
    } else if (variant === 'primary') {
        return theme.colors.raredealsButtonPrimaryBgColor;
    } else if (variant === 'secondary') {
        return theme.colors.raredealsButtonSecondaryBgColor;
    }

    return theme.colors.raredealsButtonTertiaryBgColor;
};

/**
 * The `getButtonBgHoverColor` function determines the background color for buttons when hovered based on their variant type.
 * This utility function supports several button styles—'ghost', 'primary', 'secondary', and defaults to 'tertiary'.
 * By specifying the variant, it returns the corresponding background color from a centralized theme configuration.
 *
 * @param variant A string indicating the button variant, which directly affects the button's background color.
 * @returns  A string representing the CSS color value for the button's background based on the specified variant.
 */
export const getButtonBgHoverColor = (variant: ButtonVariant) => {
    if (variant === 'ghost') {
        return 'transparent';
    } else if (variant === 'primary') {
        return theme.colors.raredealsButtonSecondaryBgColor;
    } else if (variant === 'secondary') {
        return theme.colors.raredealsButtonSecondaryBgColor;
    }

    return theme.colors.raredealsButtonTertiaryBgHoverColor;
};

/**
 * The `getButtonColor` function determines the text color for buttons based on their variant type.
 * This utility function supports several button styles—'ghost', 'primary', 'secondary', and defaults to 'tertiary'.
 * By specifying the variant, it returns the corresponding text color from a centralized theme configuration.
 *
 * @param variant A string indicating the button variant, which directly affects the button's text color.
 * @returns A string representing the CSS color value for the button's text based on the specified variant.
 */
export const getButtonColor = (variant: ButtonVariant) => {
    if (variant === 'primary') {
        return theme.colors.raredealsLightFontColor;
    } else if (variant === 'secondary') {
        return theme.colors.raredealsLightFontColor;
    }

    return theme.colors.raredealsPrimaryFontColor;
};