/* eslint-disable max-len */
import React from 'react';

interface ArrowRightProps {
    /**
     * A string representing the CSS class to be applied to the ArrowRIghtIcon element to override its default styling.
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     */
    color1?: string;
    /**
     * A number or string representing the height of the ArrowRIghtIcon element.
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     */
    testId?: string;
    /**
     * A number or string representing the height of the ArrowRIghtIcon element.
     */
    width?: number | string;
}

/**
 * The `ArrowRight` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ArrowRIghtProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ArrowRIghtIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ArrowRIghtIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ArrowRIghtIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ArrowRIghtComponent = <ArrowRight className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ArrowRight = React.forwardRef<SVGSVGElement, ArrowRightProps>((props, ref) => {
    const {className = '', color1 = '#fff', height = 17, testId = 'ArrowRight', width = 16} = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 16 17"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M3.33 8.5h9.34M8 3.83l4.67 4.67L8 13.17" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </svg>
    );
});

ArrowRight.displayName = 'ArrowRight';